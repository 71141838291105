import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';

type SpotlightPulseWrapperChildren = React.PropsWithChildren<any> | null;

export const CreateButtonSpotlightPulseWrapper = withTransparentErrorBoundary({
	attribution: Attribution.CC_ONBOARDING,
})(
	({
		condition,
		wrapper,
		children,
	}: {
		condition: boolean;
		wrapper: (children: SpotlightPulseWrapperChildren) => SpotlightPulseWrapperChildren;
		children: SpotlightPulseWrapperChildren;
	}) => (condition ? wrapper(children) : children),
);
