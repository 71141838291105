import { useQuery } from 'react-apollo';

import { fg } from '@confluence/feature-gating';
import { useCreateSpaceExperiment } from '@confluence/create-space/entry-points/createSpace';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';

import { SpaceEnabledContentTypesQuery } from '../queries/SpaceEnabledContentTypesQuery.graphql';
import type {
	SpaceEnabledContentTypesQuery as SpaceEnabledContentTypesQueryType,
	SpaceEnabledContentTypesQueryVariables,
} from '../queries/__types__/SpaceEnabledContentTypesQuery';
import { GetDefaultSpaceQuery } from '../queries/GetDefaultSpaceQuery.experimentalgraphql';
import type {
	GetDefaultSpaceQuery as GetDefaultSpaceQueryType,
	GetDefaultSpaceQueryVariables,
} from '../queries/__types__/GetDefaultSpaceQuery';

export const useSpaceEnabledContentTypes = (spaceKey: string | undefined) => {
	const { isSpaceTypesCreationFlowEnabled } = useCreateSpaceExperiment();
	const { isLivePagesFeatureEnabled } = useIsLivePagesFeatureEnabled();

	const isFeatureWhichRequiresEditFeaturesEnabled =
		isSpaceTypesCreationFlowEnabled ||
		isLivePagesFeatureEnabled ||
		fg('confluence_enable_edit_features_space_level');

	const { data: defaultSpaceKeyData } = useQuery<
		GetDefaultSpaceQueryType,
		GetDefaultSpaceQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GetDefaultSpaceQuery,
		{
			skip: !isFeatureWhichRequiresEditFeaturesEnabled || spaceKey !== undefined,
		},
	);

	const { data: enabledContentTypesData } = useQuery<
		SpaceEnabledContentTypesQueryType,
		SpaceEnabledContentTypesQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceEnabledContentTypesQuery,
		{
			errorPolicy: 'all',
			skip: !isFeatureWhichRequiresEditFeaturesEnabled || (!spaceKey && !defaultSpaceKeyData),
			variables: {
				spaceKey: spaceKey || defaultSpaceKeyData?.experimentalPromotedSpaces?.nodes?.[0]?.id,
			},
		},
	);

	const defaultReturnValue = !isSpaceTypesCreationFlowEnabled;

	const isCreateWhiteboardEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isWhiteboardsEnabled
		: defaultReturnValue;

	const isCreateBlogEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isBlogsEnabled
		: defaultReturnValue;

	const isCreateDatabaseEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isDatabasesEnabled
		: defaultReturnValue;

	const isCreateSmartLinkEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isEmbedsEnabled
		: defaultReturnValue;

	const isCreateLiveDocEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isLivePagesEnabled
		: false;

	const isCreateFolderEnabled = enabledContentTypesData
		? enabledContentTypesData?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled
		: defaultReturnValue;

	return {
		isCreateWhiteboardEnabled,
		isCreateBlogEnabled,
		isCreateDatabaseEnabled,
		isCreateSmartLinkEnabled,
		isCreateLiveDocEnabled,
		isCreateFolderEnabled,
	};
};
