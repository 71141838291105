import { fg } from '@confluence/feature-gating';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';

export const useCreateSpaceExperiment = () => {
	const { isLivePagesFeatureEnabled } = useIsLivePagesFeatureEnabled();

	const isSpaceTypesCreationFlowEnabled =
		isLivePagesFeatureEnabled || fg('space_types_creation_flow');

	return { isSpaceTypesCreationFlowEnabled };
};
