import type { FC } from 'react';
import React, { useState, useCallback, useContext } from 'react';

import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { LoadableAfterPaint } from '@confluence/loadable';
import { START } from '@confluence/navdex';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { usePageSpaceKey } from '@confluence/page-context';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { useSpaceEnabledContentTypes } from '../useSpaceEnabledContentTypes';

import { GlobalCreateContentButton } from './GlobalCreateContentButton';

export const GlobalCreateContentPopover: FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [spaceKey] = usePageSpaceKey();
	const { match } = useContext(RoutesContext);
	const isOnEditRoute = useIsEditorPage();
	const isNav4Enabled = useIsNav4Enabled();
	const onClick = useCallback(() => {
		setIsOpen(!isOpen);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'globalCreateContentPopover',
				source: 'globalNavigation',
				attributes: {
					selectedItemPageContext: match?.name,
					navigationLayer: 'global',
					navigationContainer: 'top',
					navVersion: isNav4Enabled ? '4' : '3',
					navdexPointType: START,
					isOnEditRoute,
				},
			},
		}).fire();
	}, [setIsOpen, isOpen, createAnalyticsEvent, match, isOnEditRoute, isNav4Enabled]);

	const {
		isCreateWhiteboardEnabled,
		isCreateBlogEnabled,
		isCreateLiveDocEnabled,
		isCreateDatabaseEnabled,
		isCreateSmartLinkEnabled,
	} = useSpaceEnabledContentTypes(spaceKey);

	const popupContent = useCallback(
		() => (
			<GlobalCreateContentPopoverMenuLoader
				closePopup={() => setIsOpen(false)}
				isCreateBlogEnabled={isCreateBlogEnabled}
				isCreateLiveDocEnabled={isCreateLiveDocEnabled}
				isCreateWhiteboardEnabled={isCreateWhiteboardEnabled}
				isCreateDatabaseEnabled={isCreateDatabaseEnabled}
				isCreateSmartLinkEnabled={isCreateSmartLinkEnabled}
			/>
		),
		[
			isCreateBlogEnabled,
			isCreateLiveDocEnabled,
			isCreateWhiteboardEnabled,
			isCreateDatabaseEnabled,
			isCreateSmartLinkEnabled,
		],
	);

	const popupTrigger = useCallback(
		(triggerProps: any) => (
			<>
				<SpotlightTarget name="space-types-changeboarding-target">
					<GlobalCreateContentButton onClick={onClick} triggerProps={triggerProps} />
				</SpotlightTarget>
			</>
		),
		[onClick],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			content={popupContent}
			trigger={popupTrigger}
			testId="create-content-popover"
			shouldRenderToParent
		/>
	);
};

const GlobalCreateContentPopoverMenuLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalCreateContentPopoverMenu" */ './GlobalCreateContentPopoverMenu'
			)
		).GlobalCreateContentPopoverMenu,
	_noPreloadWhenRenderingSPA: true,
});
